import React, { useCallback, useContext, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import {
  QUERY_GET_USER,
  QUERY_LIST_USERS,
  MUTATION_UPDATE_USER,
  MUTATION_UPDATE_NOTIFICATION_PREFERENCES,
  QUERY_GET_NOTIFICATION_PREFERENCES,
  GET_ADMIN_ROLES,
} from '../../../queries/UserQueries';
import UserForm from './components/UserForm';
import routes from 'constants/routes';
import { Tabs, Button, Col, Popconfirm, Row, message } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useTable from 'components/CCCTable/useTable';
import CCCTable from 'components/CCCTable/CCCTable';
import {
  MUTATION_UPDATE_LOCATION_ROLE,
  QUERY_LIST_LOCATION_ROLES,
} from '../Locations/queries';
import dayjs from 'dayjs';
import { FULL_DATE } from '../../../constants/dateformats';
import stripTypename from '../../../services/stripTypename';
import { UserContext } from '../../../contexts/UserContext';
import AssignmentTable from '../../../components/Tables/AssignmentTable';
import { FIELDS_NAMES } from 'components/Tables/const';
import UserPreferencesForm from './components/UserPreferencesForm';
import DangerZone from './components/DangerZone';
import { QUERY_USER_FEE_LEDGER } from '../../../queries/FeeLedgerQueries';
import FeeLedgerTable from '../../../components/Tables/FeeLedgerTable';
import FeeTransactionsTable from '../../../components/Tables/FeeTransactionsTable';
import PermissionsAlert from '../../../components/PermissionsAlert/PermissionsAlert';
import Loader from '../../../components/Loader/Loader';
import { QUERY_USER_FEE_TRANSACTIONS } from '../../../queries/FeeTransactionsQueries';

const UsersEdit = () => {
  const navigate = useNavigate();
  const { user_id } = useParams();
  const { dbUser } = useContext(UserContext);
  const { setTableRef, refetch } = useTable();
  const [messageApi, contextHolder] = message.useMessage();

  const {
    data,
    loading,
    error,
    refetch: refetchUser,
  } = useQuery(QUERY_GET_USER, {
    variables: { user_id },
  });

  const { data: adminRolesData } = useQuery(GET_ADMIN_ROLES, {
    fetchPolicy: 'network-only',
  });

  const { data: feeLedgerData, error: feeLedgerError } = useQuery(
    QUERY_USER_FEE_LEDGER,
    {
      variables: {
        user_id,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    },
  );

  const { data: feeTransactionsData, error: feeTransactionsError } = useQuery(
    QUERY_USER_FEE_TRANSACTIONS,
    {
      variables: {
        user_id,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    },
  );

  const {
    data: dataNotificationPreferences,
    loading: loadingNotificationPreferences,
    error: errorNotificationPreferences,
  } = useQuery(QUERY_GET_NOTIFICATION_PREFERENCES, {
    variables: { user_id },
  });
  const [updateUser] = useMutation(MUTATION_UPDATE_USER);
  const [updateLocationRole] = useMutation(MUTATION_UPDATE_LOCATION_ROLE);
  const [updateNotificationPreferences] = useMutation(
    MUTATION_UPDATE_NOTIFICATION_PREFERENCES,
  );
  const submit = useCallback(
    async ({ ...input }: any) => {
      const response = await updateUser({
        variables: {
          user_id,
          input,
        },
        refetchQueries: [
          {
            query: QUERY_GET_USER,
            variables: {
              user_id,
            },
          },
          {
            query: QUERY_LIST_USERS,
          },
        ],
      });
      const responseData = response?.data?.updateUser;
      if (responseData?.success) {
        messageApi.success(responseData?.error_message?.message);
        setTimeout(() => {
          navigate(routes.USERS);
        }, 1300);
      } else {
        messageApi.error(responseData?.error_message?.message);
        setTimeout(() => {
          navigate(routes.USERS);
        }, 3000);
      }
    },
    [navigate, updateUser, user_id],
  );
  const submitNotificationPreferences = useCallback(
    async (prefs: string) => {
      await updateNotificationPreferences({
        variables: {
          user_id,
          input: {
            prefs,
          },
        },
      });
    },
    [navigate, user_id],
  );

  const locationRoleColumns: any[] = useMemo(
    () => [
      {
        title: 'Location',
        dataIndex: ['location', 'name'],
        render: (loc_name: string) => {
          return loc_name || '- All -';
        },
      },
      {
        title: 'Full Name',
        dataIndex: ['user', 'full_name'],
      },
      {
        title: 'Role',
        dataIndex: ['role', 'name'],
      },
      {
        title: 'Assigned By',
        dataIndex: ['assigned_by', 'full_name'],
      },
      {
        title: 'Assigned On',
        dataIndex: 'assigned_on',
        render: (assigned_on: string) =>
          assigned_on ? dayjs(assigned_on).format(FULL_DATE) : null,
      },

      {
        title: 'Disabled On',
        dataIndex: 'disabled_on',
        render: (disabled_on: string) =>
          disabled_on ? dayjs(disabled_on).format(FULL_DATE) : null,
        width: 120,
      },
      ...(dbUser?.userPermissions.ManageLocations
        ? [
            {
              title: 'Actions',
              key: 'actions',
              render: (record: any) => (
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={async () => {
                    const input: any = {};
                    for (const key in record) {
                      if (typeof record[key] !== 'object') {
                        input[key] = record[key];
                      }
                    }
                    delete input.location_role_id;

                    await updateLocationRole({
                      variables: {
                        location_role_id: record.location_role_id,
                        input: {
                          ...stripTypename(input),
                          ...(!record.disabled_on
                            ? {
                                disabled_on: new Date(),
                                disabled_by_id: dbUser ? dbUser.user_id : null,
                              }
                            : {
                                disabled_on: null,
                                disabled_by_id: null,
                              }),
                        },
                      },
                    });
                    refetch();
                  }}
                >
                  <a>{record.disabled_on ? 'Activate' : 'Disable'}</a>
                </Popconfirm>
              ),
            },
          ]
        : []),
    ],
    [dbUser],
  );

  if (loading) {
    return <Loader />;
  }

  if (!dbUser?.userPermissions.ManageUsers) {
    return <PermissionsAlert />;
  }

  const tabItems = [
    {
      key: '1',
      label: 'Location Roles',
      children: (
        <CCCTable
          ref={setTableRef}
          rowKey="location_role_id"
          query={QUERY_LIST_LOCATION_ROLES}
          queryKey="listLocationRole"
          columns={locationRoleColumns}
          where={{ user_id: { eq: user_id } }}
        />
      ),
    },
    {
      key: '2',
      label: "User's Assignments",
      children: (
        <AssignmentTable
          where={{ user_id: { eq: user_id } }}
          hiddenColumns={[FIELDS_NAMES.USER]}
        />
      ),
    },
    {
      key: '3',
      label: 'Notifications Preferences',
      children:
        !loadingNotificationPreferences && !errorNotificationPreferences ? (
          <UserPreferencesForm
            data={dataNotificationPreferences.getNotificationPreferences}
            handleSubmit={submitNotificationPreferences}
          />
        ) : errorNotificationPreferences ? (
          `Error: ${errorNotificationPreferences.message}`
        ) : (
          'loading...'
        ),
    },
    {
      key: '4',
      label: 'Fee Ledger',
      children:
        feeLedgerData && !feeLedgerError ? (
          <FeeLedgerTable
            data={feeLedgerData?.getFeeLedgerByUser}
            query={QUERY_USER_FEE_LEDGER}
            isAssignmentIdShown
          />
        ) : null,
    },
    {
      key: '5',
      label: 'Transactions',
      children:
        feeTransactionsData && !feeTransactionsError ? (
          <FeeTransactionsTable
            data={feeTransactionsData?.getFeeTransactions}
          />
        ) : null,
    },
  ];

  return (
    <>
      <Row style={{ paddingLeft: 18 }}>
        <Row align={'middle'} justify={'space-between'}>
          <Col span={12}>
            {!loading && !error ? (
              <UserForm
                user={data.getUser}
                adminRoles={adminRolesData?.getAdminRoles}
                handleSubmit={submit}
                refetchUser={refetchUser}
              />
            ) : error ? (
              'Error:' + error.message
            ) : (
              'loading...'
            )}
          </Col>
          {dbUser?.userPermissions.DangerZone &&
            data?.getUser.firebase_user_id &&
            !data?.getUser.email.includes('DELETED') && (
              <Col span={11}>
                <DangerZone user={data?.getUser} />
              </Col>
            )}
        </Row>
        <Col span={24} style={{ marginTop: 50 }}>
          <Tabs
            items={tabItems}
            tabBarExtraContent={
              dbUser?.userPermissions.ManageLocations
                ? {
                    right: (
                      <Link
                        to={'/locations/role/create'}
                        state={{
                          user: data ? data.getUser : { user_id },
                          backTo: routes.USERS + '/' + user_id,
                        }}
                      >
                        <Button type="primary">Create Location Role</Button>
                      </Link>
                    ),
                  }
                : null
            }
          ></Tabs>
        </Col>
      </Row>
      {contextHolder}
    </>
  );
};

export default UsersEdit;
