import { Button, Input, Modal, Radio, Select, Space, Spin } from 'antd';
import { AdminRole } from '../utils/corpClientTypes';
import Permissions from '../../../../constants/Permissions';
import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import AdminRoles from '../../../../constants/AdminRoles';
import { RadioChangeEvent } from 'antd';
import { useQuery } from '@apollo/client';
import { GET_ADMIN_ROLES } from '../../../../queries/UserQueries';

export interface AdminPermissionsOptions {
  title: string;
  submitTitle: string;
  isPermissionsEditingEnabled: boolean;
  handleSubmit: (values: any) => void;
}

export interface AdminPermissionsModalRef {
  handleModalOpen: (
    email?: string,
    user_id?: number,
    corporateClientId?: number,
  ) => void;
  handleCancel: () => void;
}

const AdminPermissionsModal = React.forwardRef<
  AdminPermissionsModalRef,
  AdminPermissionsOptions
>(
  (
    {
      title,
      submitTitle,
      isPermissionsEditingEnabled,
      handleSubmit,
    }: AdminPermissionsOptions,
    ref,
  ) => {
    const [loading, setLoading] = useState(false);
    const [accessLevel, setAccessLevel] = useState(AdminRoles.Member);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [userEmail, setUserEmail] = useState<string | undefined | null>(null);
    const [userId, setUserId] = useState<number | undefined | null>(null);
    const [corpClientId, setCorpClientId] = useState<number | undefined | null>(
      null,
    );

    const [form] = Form.useForm();

    const { data: adminRolesData } = useQuery(GET_ADMIN_ROLES, {
      fetchPolicy: 'network-only',
    });

    useEffect(() => {
      if (adminRolesData) {
        const initEvent = {
          target: {
            value: AdminRoles.Member,
          },
        } as RadioChangeEvent;

        handleAccessLevelChange(initEvent);
      }
      if (userEmail) {
        form.setFieldsValue({ email: userEmail });
      }
      if (isModalVisible) {
        form.setFieldsValue({
          email: userEmail,
          userId: userId,
          corporateClientId: corpClientId,
        });
      }
    }, [adminRolesData, userEmail]);

    const handleModalOpen = (
      email?: string,
      user_id?: number,
      corporateClientId?: number,
    ) => {
      if (userEmail) {
        form.setFieldsValue({ email: userEmail });
      }
      setUserEmail(email);
      setUserId(user_id);
      setCorpClientId(corporateClientId);
      setIsModalVisible(true);
    };

    const handleCancel = () => {
      setIsModalVisible(false);
      form.resetFields();
      if (adminRolesData) {
        const initEvent = {
          target: {
            value: AdminRoles.Member,
          },
        } as RadioChangeEvent;

        handleAccessLevelChange(initEvent);
      }
    };

    React.useImperativeHandle(ref, () => ({
      handleModalOpen,
      handleCancel,
    }));

    function handleAccessLevelChange(e: RadioChangeEvent) {
      const newAccessLevel = e.target.value;
      setAccessLevel(newAccessLevel);

      const selectedAdminRole = adminRolesData?.getAdminRoles.find(
        (adminRole: any) => adminRole.role_id === newAccessLevel,
      );

      if (selectedAdminRole) {
        const selectedPermissions = selectedAdminRole.permissions.map(
          (p: any) => p.permission_id,
        );

        if (form) {
          form.setFieldsValue({
            permissions: selectedPermissions,
            accessLevel: newAccessLevel,
          });
        }
      }
    }

    const submit = async () => {
      setLoading(true);
      const values = await form.validateFields();
      try {
        await handleSubmit(values);
      } finally {
        setLoading(false);
      }
    };

    return (
      <Modal
        forceRender
        title={title}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null} // Custom footer with Submit button
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            userId: userId,
            corporateClientId: corpClientId,
            permissions: [],
            accessLevel: accessLevel,
          }}
          onFinish={submit}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Please enter an email!' },
              {
                type: 'email',
                message: 'Please enter a valid email!',
              },
            ]}
          >
            <Input placeholder="Enter email" disabled={!!userEmail} />
          </Form.Item>
          <Form.Item label="Access level" name="accessLevel">
            <Radio.Group onChange={handleAccessLevelChange}>
              <Space direction="vertical">
                {adminRolesData?.getAdminRoles &&
                  adminRolesData?.getAdminRoles
                    .filter((adminRole: AdminRole) => adminRole.role_id <= 3)
                    .map((adminRole: AdminRole) => (
                      <div key={adminRole.role_id}>
                        <Radio
                          key={adminRole.role_id}
                          value={adminRole.role_id}
                          disabled={!adminRole.isEnabled}
                        >
                          {adminRole.name}
                        </Radio>
                        <div style={{ fontSize: '12px', color: '#888' }}>
                          {adminRole.description || ''}
                        </div>
                      </div>
                    ))}
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Permissions" name="permissions">
            <Select
              mode="multiple"
              placeholder="Select permissions"
              disabled={!isPermissionsEditingEnabled}
            >
              {Object.entries(Permissions).map(([key, value]) => (
                <Select.Option key={value} value={value}>
                  {key.replace(/([A-Z])/g, ' $1').trim()}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {/* Hidden Inputs */}
          <Form.Item name="userId" hidden>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="corporateClientId" hidden>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={loading}>
              {loading ? <Spin size="small" /> : submitTitle}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  },
);

AdminPermissionsModal.displayName = 'AdminPermissionsModal';
export default AdminPermissionsModal;
